<template>
  <div>
    <div class="header">
      <img
        :src="logo"
        alt="company-logo"
        class="header__logo"
      >
      <div class="header__qr" @click="$emit('onClickQrCode')" v-if="qrVisible">
        <SVGViewer img="qr-code" />
      </div>
    </div>
  </div>
</template>

<script>
import SVGViewer from '@/components/SVGViewer.vue';

export default {
  name: 'Header',
  components: { SVGViewer },
  props: {
    logo: {
      type: String,
      required: true,
    },
    qrVisible: Boolean,
  },
};
</script>

<style scoped lang="sass">
@import "./Header.sass"
</style>
