import Vue from 'vue';
import VueMeta from 'vue-meta';
import { InlineSvgPlugin } from 'vue-inline-svg';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import './plugins/croppie';

Vue.config.productionTip = false;

Vue.use(InlineSvgPlugin);
Vue.use(VueMeta);

Vue.mixin({
  methods: {
    lockScreen() {
      this.$store.commit('enablePageIsLoading');
    },
    unlockScreen() {
      this.$store.commit('disablePageIsLoading');
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
