<template>
  <img
    :class="
        vertical ? 'AppTitle__vertical' : 'AppTitle'"
    :src="require('@/assets/mycontact.svg')"
    alt="mycontact"
  />
</template>

<script>

export default {
  name: 'AppTitle',
  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="sass">

@import "./AppTitle.sass"
</style>
