export default class InputImage {
  constructor(data) {
    this.url = data.url;
    this.data = data.data;
    this.extension = data.extension;
  }

  get getUrl() {
    return this.url;
  }

  get getData() {
    return this.data;
  }

  get getExtension() {
    return this.extension;
  }

  getImageDataUrl() {
    if (this.getUrl) {
      return this.getUrl;
    }
    return `data:image/${this.getExtension};base64,${this.getData}`;
  }
}
