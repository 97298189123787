import Vue from 'vue';
import VueRouter from 'vue-router';
import Company from '@/views/Company.vue';
import store from '@/store/index';
import Home from '@/views/Home.vue';
import CompanyDashboard from '@/views/CompanyDashboard.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import FreeSignin from '@/views/FreeSignin.vue';
import FreeAuth from '@/views/FreeAuth.vue';
import FreeEditCard from '@/views/FreeEditCard.vue';
import FreeViewCard from '@/views/FreeViewCard.vue';
import FreeHomeCard from '@/views/FreeHomeCard.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    beforeEnter() {
      window.location.href = 'https://www.mycontact.app';
    },
  },
  {
    path: '/signin',
    name: 'free-signin',
    component: FreeSignin,
    meta: {
      public: true,
    },
  },
  {
    path: '/users/auth/:token',
    name: 'auth',
    component: FreeAuth,
    meta: {
      public: true,
    },
  },
  {
    path: '/users/edit',
    name: 'free-edit-card',
    component: FreeEditCard,
    meta: {
      public: false,
    },
  },
  {
    path: '/users/:user_id/view',
    name: 'free-view-card',
    component: FreeViewCard,
    meta: {
      public: true,
    },
  },
  {
    path: '/users/home',
    name: 'free-home-card',
    component: FreeHomeCard,
    meta: {
      public: false,
    },
  },
  {
    path: '/:company',
    name: 'company-dashboard',
    component: CompanyDashboard,
    meta: {
      public: true,
    },
  },
  {
    path: '/:company/dir',
    name: 'company',
    component: Company,
    meta: {
      public: true,
    },
  },
  {
    path: '/:company/:username',
    name: 'user-card',
    component: Home,
    meta: {
      title: 'About Page - :username',
      public: true,
    },
  },
  {
    path: '/404',
    name: 'page-not-found',
    component: PageNotFound,
    meta: {
      public: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { hasSession, token, user } = store.getters;
  if (!hasSession) {
    store.dispatch('createSession');
  }
  if (to.meta.public === false) {
    if (Object.keys(user).length === 0) {
      if (!token) {
        next({ name: 'free-signin' });
        return;
      }

      let userFromToken = null;
      try {
        userFromToken = await store.dispatch('getUserByToken', token);
      } catch (e) {
        console.error(e);
      }
      if (!userFromToken) {
        next({ name: 'free-signin' });
        return;
      }

      if (userFromToken.isEmpty()) {
        next({ name: 'free-edit-card' });
        return;
      }
    }
  }

  next();
});

export default router;
