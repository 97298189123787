<template>
  <div class="input">
    <div class="input__label">
      {{ label }}
    </div>
    <div class="input__input">
      <input
        :type="type"
        :value="value"
        @input="$emit('input', $event.target.value)"
        @keypress="$emit('keypress', $event)"
        :class="{ 'has-error': hasError }"
        :placeholder="placeholder"
        :pattern="pattern"
        :disabled="disabled"
      />
    </div>
    <div v-if="hasError" class="input__error">This field is invalid</div>
  </div>
</template>

<script>
export default {
  name: 'Input',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: String,
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pattern: String,
    hasError: Boolean,
  },
};
</script>

<style lang="sass" scoped>
  @import "@/style/settings/colors"
  @import "@/style/settings/size"
  .input
    &__label
      color: $color__text--free
      margin-bottom: 5px
    &__input
      margin-bottom: 5px
      input
        padding: 10px 15px
        border-radius: 10px
        border: 1px solid #EEF2FE
        width: 100%
        caret-color: $color__primary--free
        outline: none
        appearance: none
        -moz-appearance: none
        -webkit-appearance: none
        &.has-error
          border-color: $color__primary--free
    &__error
      color: $color__primary--free
      font-size: $size__S
</style>
