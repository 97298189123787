export default class Headquarter {
  constructor(headquarter) {
    const parsedAddress = encodeURIComponent(headquarter.address);
    this.address = headquarter.address;
    this.maps_url = `https://www.google.com/maps/dir//${parsedAddress}/`;
  }

  get getAddress() {
    return this.address;
  }

  get getMapsUrl() {
    return this.maps_url;
  }
}
