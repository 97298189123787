import BrandingTransformer from '@/transformers/BrandingTransformer';

class CompanyTransformer {
  static fetch(company) {
    return {
      company: company.name,
      logo: company.logo,
      company_url: company.web,
      branding: BrandingTransformer.fetch(company.branding),
    };
  }
}

export default CompanyTransformer;
