<template>
  <button
    class="ButtonLogout"
    @click.prevent="handleLogout"
  >
    <img src="@/assets/logout.svg" /> Logout
  </button>
</template>

<script>

export default {
  name: 'ButtonLogout',
  methods: {
    handleLogout() {
      this.$emit('handleLogout');
    },
  },
};
</script>

<style scoped lang="sass">
@import "@/style/settings/size"
@import "@/style/settings/colors"

.ButtonLogout
  display: flex
  margin-top: 80px
  justify-content: center
  align-items: center
  font-size: $size__M
  font-weight: 600
  color: $color__text--free
  cursor: pointer
  width: fit-content
  background-color: transparent
  border-width: 0
  img
    margin-right: .5rem
</style>
