<template>
  <button
    type="button"
    :disabled="disabled"
    :class="classes"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>

export default {
  props: {
    classes: Object,
    disabled: Boolean,
  },
};
</script>

<style lang="sass" scoped>
@import "@/style/settings/colors"
button
  width: 100%
  background-color: $color__primary--free
  color: $white
  border: none
  border-radius: 10px
  font-weight: 600
  padding: 15px
  &:active
    background-color: $color__primary__alpha030--free
  &.disabled
    opacity: 0.1
</style>
