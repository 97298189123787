<template>
  <div class="container__content" v-if="dataIsLoaded">
    <div class="company__dashboard">
      <img :src="this.company.logo" alt="company-logo">
    </div>
  </div>
</template>

<script>

import CompanyAPI from '@/api/CompanyAPI';

export default {
  name: 'CompanyDashboard',
  data: () => ({
    company: {},
    dataIsLoaded: false,
  }),
  async created() {
    const companyAPI = new CompanyAPI();
    try {
      this.company = await companyAPI.getCompany(this.$route.params.company);
      this.dataIsLoaded = true;
    } catch (e) {
      // this.$router.push({ name: 'page-not-found' });
    }
  },
};
</script>
<style scoped lang="sass">
@import "./CompanyDashboard"
</style>
