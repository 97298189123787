import * as localStorage from '@/utils/localStorage';

export default {
  enablePageIsLoading(state) {
    state.pageIsLoading = true;
  },
  disablePageIsLoading(state) {
    state.pageIsLoading = false;
  },
  createSession(state, session) {
    localStorage.saveSession(session);
  },
  setToken(state, token) {
    localStorage.setToken(token);
    state.token = token;
  },
  unsetToken() {
    localStorage.unsetToken();
  },
  setUser(state, user) {
    state.user = user;
  },
  unsetUser(state) {
    state.user = {};
  },
};
