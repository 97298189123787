class HeadQuarterTransformer {
  static fetch(headQuarter) {
    return {
      name: headQuarter.name,
      address: headQuarter.address,
      maps_url: headQuarter.googleMapsLink,
      logo: headQuarter.logo,
    };
  }
}

export default HeadQuarterTransformer;
