class StatTransformer {
  static send(eventData) {
    return {
      employeeId: eventData.userId,
      freeCardId: eventData.freeCardId,
      event: eventData.event,
      userAgent: eventData.userAgent,
      referrer: eventData.referrer !== '' ? eventData.referrer : null,
      session: eventData.session,
      userType: eventData.userType,
    };
  }
}

export default StatTransformer;
