<template>
  <div class="container__content" v-if="dataIsLoaded">
    <Header :logo="company.logo" />
    <FilterInput @changeFilter="onChangeFilter"></FilterInput>
    <div class="list" v-if="hasFilteredUsers">
      <Member v-for="(user, index) in filteredUsers" :key="index" :user="user" />
    </div>
    <div class="list__no-result" v-else>
      No hay resultados
    </div>
  </div>
</template>

<script>
import UsersAPI from '@/api/UsersAPI';
import Member from '@/components/Member.vue';
import Header from '@/components/Header.vue';
import normalize from '@/utils/normalizer';
import FilterInput from '@/components/FilterInput.vue';
import CompanyAPI from '@/api/CompanyAPI';

export default {
  name: 'Company',
  components: { FilterInput, Header, Member },
  data: () => ({
    company: null,
    users: [],
    filteredUsers: [],
    dataIsLoaded: false,
  }),
  async created() {
    const usersApi = new UsersAPI();
    const companyApi = new CompanyAPI();
    try {
      this.users = await usersApi.getAll(this.$route.params.company);
      this.company = await companyApi.getCompany(this.$route.params.company);
    } catch (e) {
      // this.$router.push({ name: 'page-not-found' });
    }
    this.filteredUsers = this.users;
    this.dataIsLoaded = true;
  },
  computed: {
    hasFilteredUsers() {
      return this.filteredUsers.length > 0;
    },
  },
  methods: {
    onChangeFilter(value) {
      this.filteredUsers = this.users.filter((user) => {
        const normalizedUserName = normalize(user.name);
        const normalizedValue = normalize(value);

        return normalizedUserName.includes(normalizedValue);
      });
    },
  },
};
</script>
