<template>
  <div class="Menu">
    <div class="Menu__outArea" @click.prevent="handleClickOut"/>
    <div class="Menu__frame">
      <img
        class="Menu__mobileBar"
        src="@/assets/mobile-bar.svg"
      />
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  methods: {
    handleClickOut() {
      this.$emit('handleClickOut');
    },
  },
};
</script>

<style scoped lang="sass">
@import "./Menu.sass"
</style>
