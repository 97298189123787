<template>
  <div class="InstallAlert">
    <button
        class="InstallAlert__closeButton"
        @click="handleClose"
      >
        <img src="@/assets/close.svg" />
      </button>
      <p className="InstallAlert__textContainer">
        <span>
          Install this Web-App in your home screen: tap
        </span>
        <img
          class="InstallAlert__icon"
        :src="isIosDevice
          ? require('@/assets/ios_share_icon.png')
          : require('@/assets/android_share_icon.png')"
        />
        <span>
          and "Add to home screen".
        </span>
      </p>
  </div>
</template>

<script>
import { isIos } from '@/utils/deviceHelper';

export default {
  name: 'InstallAlert',
  methods: {
    handleClose() {
      this.$emit('handleClose');
    },
  },
  computed: {
    isIosDevice() {
      return isIos();
    },
  },
};
</script>

<style scoped lang="sass">
@import "./InstallAlert.sass"
</style>
