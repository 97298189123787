<template>
  <img :src="logo" alt="user-logo" class="imgShadow"/>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    logo: String,
  },
};
</script>
