<template>
  <div class="input">
    <div class="input__label">
      {{ label }}
    </div>
    <div class="color">
      <div class="color__hex">
        <input
          type="text"
          :value="value"
          @input="$emit('input', $event.target.value)"
          placeholder="#000000"
        />
      </div>
      <div class="color__input">
        <input
          id="color"
          name="color"
          type="color"
          :value="value"
          @input="$emit('input', $event.target.value)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputColor',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: String,
  },
};
</script>

<style lang="sass" scoped>
  @import "@/style/settings/colors"
  .input
    &__label
      color: $color__text--free
      margin-bottom: 5px
    .color
      display: flex
      background-color: $white
      justify-content: space-between
      align-items: center
      border-radius: 10px
      border: 1px solid #EEF2FE
      margin-bottom: 15px
      width: 100%
      &__hex
        input
          padding: 10px 15px
          caret-color: $color__primary--free
          outline: none
          border-width: 0
          border-radius: 10px
      &__input
        padding-right: 10px
        line-height: 0
        input
          outline: none
          border: none
          height: 25px
          width: 25px
          padding: 0
          margin: 0
          border-radius: 10px
          &::-webkit-color-swatch
            border: none
            border-radius: 5px
          &::-webkit-color-swatch-wrapper
            padding: 0
            border-radius: 5px
          &::-moz-color-swatch
            border: none
            border-radius: 5px
</style>
