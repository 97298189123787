import createUserSession from '@/utils/createUserSession';

const KEY = 'cards-session';
const TOKEN_KEY = 'ml-token';

function calculateTTL() {
  const now = new Date();
  const ttl = 2 * 60;
  return now.getTime() + (ttl * 1000);
}

function save(session) {
  localStorage.setItem(KEY, JSON.stringify({
    value: session,
    expiry: calculateTTL(),
  }));
}

function getSessionData() {
  return localStorage.getItem(KEY);
}

export const saveSession = (session) => {
  save(session);
};

export const getSession = () => {
  const sessionData = getSessionData();
  if (!sessionData) {
    save(createUserSession());
    const item = JSON.parse(getSessionData());

    return item.value;
  }

  let item = JSON.parse(sessionData);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(KEY);
    save(createUserSession());
    item = JSON.parse(getSessionData());

    return item.value;
  }

  return item.value;
};

export const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const unsetToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const showInstallAlert = () => localStorage.getItem('showInstallAlert');

export const hideInstallAlert = () => {
  localStorage.setItem('showInstallAlert', 'false');
};
