<template>
  <div class="magic-link">
    <Input
      label="Email address"
      placeholder="Your email address"
      type="email"
      :value="value"
      @input="onInput"
      @keypress="onKeyPress"
    />
    <div class="form">
      <div class="form__submit">
        <Submit
          :disabled="!valid"
          :classes="{ disabled: !valid }"
          @click="$emit('next')"
        >Next</Submit>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/Form/Input.vue';
import Submit from '@/components/Form/Submit.vue';

export default {
  components: { Input, Submit },
  name: 'MagicLink',
  props: {
    value: String,
  },
  data: () => ({
    valid: false,
  }),
  methods: {
    onInput(e) {
      this.$emit('input', e);
      this.valid = String(e)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ) !== null;
    },
    onKeyPress(e) {
      if (e.keyCode === 13 && this.valid) {
        this.$emit('next');
      }
    },
  },
};
</script>

<style scoped lang="sass">
@import "./MagicLink.sass"
</style>
