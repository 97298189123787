import * as localStorage from '@/utils/localStorage';

export default {
  hasSession() {
    return localStorage.getSession() !== null;
  },
  token() {
    return localStorage.getToken();
  },
  user(state) {
    return state.user;
  },
};
