<template>
  <div
    class="container__content"
  >
    <div class="free-auth">
      <pulse-loader color="#000" size="10px"></pulse-loader>
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import store from '@/store/index';

export default {
  components: { PulseLoader },
  mounted() {
    store.dispatch('getUserByToken', this.$route.params.token)
      .then(() => {
        if (this.$store.getters.user.isEmpty()) {
          this.$router.push({ name: 'free-edit-card' });
          return;
        }
        this.$router.push({ name: 'free-home-card' });
      })
      .catch(() => {
        this.$router.push({ name: 'free-signin' });
      });
  },
};
</script>

<style lang="sass" scoped>
  .free-auth
    height: 100vh
    padding-top: 33%
    text-align: center
</style>
