<template>
  <div class="spacer" :class="size" />
</template>

<script>
export default {
  name: 'Spacer',
  props: {
    size: {
      type: String,
      required: false,
      default: 'spacer__M',
    },
  },
};
</script>

<style scoped lang="sass">
@import "./Spacer.sass"
</style>
