export default class Company {
  constructor(company) {
    this.logo = company.logo;
    this.name = company.name;
    this.company_url = company.company_url;
    this.primary_color = company.primary_color;
  }

  get getLogo() {
    return this.logo;
  }

  get getName() {
    return this.name;
  }

  get getCompanyUrl() {
    if (this.company_url && !this.company_url.match(/^http/)) {
      return `https://${this.company_url}`;
    }
    return this.company_url;
  }

  get getPrimaryColor() {
    return this.primary_color;
  }
}
