<template>
  <div
    class="container__content"
    :style="companyStyleColors"
    v-if="dataIsLoaded"
  >
    <div class="FreeHome" :class="{ dim: showMenu }">
      <div class="FreeHome__titleBlock">
        <AppTitle />
      </div>
      <ActionCard
        :user="user"
        @handleUser="navigateToUser"
        @handleActions="showActionsMenu"
      />
      <div class="FreeHome__partnerBlock">
        <ButtonLogout @handleLogout="handleLogout"/>
      </div>
    </div>
    <Menu
      v-if="showMenu"
      @handleClickOut="closeActionsMenu"
    >
      <div class="FreeHome__menu">
        <button
          class="FreeHome__menu__option"
          @click.prevent="navigateToUser"
        >
          View
        </button>
         <Spacer size="spacer__XL" />
        <button class="FreeHome__menu__option" @click.prevent="navigateToUserEdition">Edit</button>
         <Spacer size="spacer__XL" />
        <button class="FreeHome__menu__option" @click.prevent="handleShare">Share by</button>
      </div>
    </Menu>
  </div>
</template>

<script>
import AppTitle from '@/components/AppTitle/AppTitle.vue';
import ActionCard from '@/components/ActionCard/ActionCard.vue';
import ButtonLogout from '@/components/Form/ButtonLogout.vue';
import Menu from '@/components/Menu/Menu.vue';
import Spacer from '@/components/Common/Spacer/Spacer.vue';
import colorTransformer from '@/utils/colorTransformer';
import store from '../store';

export default {
  name: 'FreeHomeCard',
  components: {
    AppTitle,
    ActionCard,
    Menu,
    ButtonLogout,
    Spacer,
  },
  data: () => ({
    dataIsLoaded: false,
    companyStyleColors: {},
    user: {},
    showMenu: false,
  }),
  async created() {
    this.lockScreen();
    this.user = this.$store.state.user;
    const color = this.user.company.primary_color || process.env.VUE_APP_PRIMARY_COLOR;
    this.companyStyleColors = colorTransformer(color);

    this.unlockScreen();

    this.dataIsLoaded = true;
    this.unlockScreen();
  },
  methods: {
    handleLogout() {
      store.commit('unsetToken');
      store.commit('setUser');

      this.$router.push({ name: 'free-signin' });
    },
    navigateToUser() {
      this.$router.push({
        name: 'free-view-card',
        params: {
          user_id: this.user.getId,
        },
      });
    },
    navigateToUserEdition() {
      this.$router.push({
        name: 'free-edit-card',
      });
    },
    existShareNativeOption() {
      return !!navigator.share;
    },
    handleShare() {
      if (!this.existShareNativeOption()) {
        return;
      }

      const role = this.user.getRole || '';
      const company = this.user.getCompany.getName ? `@ ${this.user.getCompany.getName}` : '';
      const query = this.$router.resolve({ name: 'free-view-card', params: { user_id: this.user.getId } }).href;
      const host = window.location.origin;
      const url = `${host}${query}`;

      const shareData = {
        title: `Digital Card from ${this.user.getFirstName}`,
        text: `${this.user.getName}'s Digital Card  - ${role} ${company}`,
        url,
      };

      navigator.share(shareData)
        .then(() => console.log('ok'))
        .catch((err) => console.log('error => ', err));
    },
    showActionsMenu() {
      this.showMenu = true;
    },
    closeActionsMenu() {
      this.showMenu = false;
    },
  },
};
</script>

<style scoped lang="sass">
@import "../style/pages/FreeHome.sass"
</style>
