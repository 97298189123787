import UserTransformer from '@/transformers/UserTransformer';
import UserFreeTransformer from '@/transformers/UserFreeTransformer';
import axios from '@/plugins/axios';

class UsersAPI {
  constructor() {
    this.service = axios;
  }

  async getAll(company) {
    const response = await this.service.get(`/companies/${company}/employees`);
    return response.data.collection.map((user) => UserTransformer.fetch(user));
  }

  async getUser(company, username) {
    let response;
    try {
      response = await this.service.get(`/companies/${company}/employees/${username}`);
    } catch (error) {
      return Promise.reject(error);
    }
    return UserTransformer.fetch(response.data);
  }

  authRequest = async (email) => {
    const data = {
      email,
    };
    this.service.post('/free/auth', data)
      .then()
      .catch((error) => Promise.reject(error));
  }

  getUserFree = async (token) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return this.service.get('free/me', config)
      .then((res) => UserFreeTransformer.fetch(res.data))
      .catch((error) => Promise.reject(error));
  }

  getUserFreeById = async (id) => this.service.get(`free/users/${id}`)
    .then((res) => UserFreeTransformer.fetch(res.data))
    .catch((error) => Promise.reject(error));

  saveUserFree = async ({ user, token }) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return this.service.put('free/me', user, config)
      .then()
      .catch((error) => Promise.reject(error.response.data));
  }
}

export default UsersAPI;
