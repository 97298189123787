<template>
  <div id="app">
    <div class="container">
      <Overlay v-if="pageIsLoading" />
      <router-view />
    </div>
  </div>
</template>

<script>
import Overlay from '@/components/Overlay/Overlay.vue';

export default {
  name: 'App',
  components: { Overlay },
  computed: {
    pageIsLoading() {
      return this.$store.state.pageIsLoading;
    },
  },
};
</script>

<style lang="sass">
  @import './style/normalize.css'
  @import './style/base.sass'
</style>
