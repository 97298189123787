import axios from '@/plugins/axios';
import * as events from '@/utils/events';

// eslint-disable-next-line no-unused-vars
import StatTransformer from '@/transformers/StatTransformer';

class StatsAPI {
  constructor() {
    this.service = axios;
  }

  // eslint-disable-next-line class-methods-use-this
  sendStat(eventData, eventName) {
    const eventsTransformer = {
      [events.VISIT_EVENT]: 'visit',
      [events.PHONE_CLICK_EVENT]: 'phone_click',
      [events.EMAIL_CLICK_EVENT]: 'email_click',
      [events.LINKEDIN_CLICK_EVENT]: 'linkedin_click',
      [events.WEB_CLICK_EVENT]: 'web_click',
      [events.ADDRESS_CLICK_EVENT]: 'address_click',
      [events.QR_CODE_CLICK_EVENT]: 'qr_code_click',
      [events.CONTACT_DOWNLOAD_EVENT]: 'contact_download',
      [events.SHARE_CLICK_EVENT]: 'share_click',
      [events.QR_CODE_DOWNLOAD_EVENT]: 'qr_code_download',
    };
    Object.assign(eventData, {
      event: eventsTransformer[eventName],
    });

    this.service.post('/stats', StatTransformer.send(eventData));
  }
}

export default StatsAPI;
