import CompanyTransformer from '@/transformers/CompanyTransformer';
import axios from '@/plugins/axios';

class CompanyAPI {
  constructor() {
    this.service = axios;
  }

  async getCompany(company) {
    let response;
    try {
      response = await this.service.get(`/companies/${company}`);
    } catch (e) {
      return Promise.reject(e);
    }

    return CompanyTransformer.fetch(response.data);
  }
}

export default CompanyAPI;
