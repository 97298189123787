<template>
  <div
    class="container__content"
    :style="companyStyleColors"
    :class="{'scrollDisabled': modalIsVisible}"
    v-if="dataIsLoaded">
    <Modal
      :qrSrc="this.qrURL"
      :visible="modalIsVisible"
      :username="this.username"
      :company="this.company"
      @onClickExitModal="modalIsVisible = false"
      @onClickDownloadQr="onClickDownloadQr"
    >
    </Modal>
    <Header
      :logo="this.user.head_quarter.logo ? this.user.head_quarter.logo : this.user.company.logo"
      qr-visible
      @onClickQrCode="onClickOpenQrCode"
    />
    <Card>
      <template v-slot:avatar>
        <div class="card__img">
          <Avatar :avatar="user.avatar" />
        </div>
      </template>
      <template v-slot:content>
        <Spacer />
        <div class="text__title">{{ user.name }}</div>
        <Spacer size="spacer__XXS" />
        <div class="text__subtitle">{{ user.role }}</div>
        <Spacer size="spacer__XXS" />
        <div class="text__subtitle">{{ user.company.company }}</div>
        <Spacer size="spacer__XXXL" />
        <a
          :href="vCardURL"
          class="btn btn__primary"
          @click="onClickAddContacts"
        >
          Añadir a contactos
        </a>
      </template>
    </Card>
    <UserBio :bio="user.bio" v-if="user.bio"></UserBio>
    <ContactInfo :user="user" @onSendStat="onSendStat"></ContactInfo>
    <Spacer size="spacer__XXL" />
    <Spacer size="spacer__XXL" />
    <button class="btnFloat" @click="handleShare" v-if="existShareNativeOption()">
      <img :src="require('@/assets/arrow.svg')" alt="arrow"/>
    </button>
  </div>
</template>

<script>
import UsersAPI from '@/api/UsersAPI';
import Header from '@/components/Header.vue';
import Card from '@/components/Card/Card.vue';
import ContactInfo from '@/components/ContactInfo.vue';
import Modal from '@/components/Modal/Modal.vue';
import Spacer from '@/components/Common/Spacer/Spacer.vue';
import Avatar from '@/components/Avatar/Avatar.vue';
import UserBio from '@/components/UserBio.vue';
import colorTransformer from '@/utils/colorTransformer';
import StatsAPI from '@/api/StatsAPI';
import * as events from '@/utils/events';
import * as userType from '@/utils/userType';
import * as localStorage from '@/utils/localStorage';

export default {
  name: 'Home',
  components: {
    UserBio,
    Avatar,
    Modal,
    ContactInfo,
    Header,
    Card,
    Spacer,
  },
  data: () => ({
    company: null,
    username: null,
    user: {},
    modalIsVisible: false,
    dataIsLoaded: false,
    vCardURL: null,
    companyStyleColors: {},
    statsApi: null,
  }),
  async created() {
    this.lockScreen();
    this.statsApi = new StatsAPI();
    // this.$route.meta.title = this.$route.params.username;

    this.company = this.$route.params.company;
    this.username = this.$route.params.username;

    const usersApi = new UsersAPI();
    try {
      this.user = await usersApi.getUser(this.company, this.username);
    } catch (e) {
      this.unlockScreen();
      // this.$router.push({ name: 'page-not-found' });
    }
    this.vCardURL = `${process.env.VUE_APP_API_URL}/companies/${this.company}/employees/${this.username}/vcard`;
    this.qrURL = `${process.env.VUE_APP_API_URL}/companies/${this.company}/employees/${this.username}/qr`;

    const companyColor = this.user.company.branding.primaryColor;
    this.companyStyleColors = colorTransformer(companyColor);

    this.dataIsLoaded = true;
    this.unlockScreen();
    this.onSendStat(events.VISIT_EVENT);
  },
  methods: {
    handleShare() {
      const shareData = {
        title: `Digital Card from ${this.user.name}`,
        text: `${this.user.name}'s Digital Card  - ${this.user.role} @ ${this.company}`,
        url: window.location.href,
      };

      navigator.share(shareData)
        .then(() => this.onSendStat(events.SHARE_CLICK_EVENT))
        .catch(() => {});
    },
    existShareNativeOption() {
      return !!navigator.share;
    },
    onClickAddContacts() {
      this.onSendStat(events.CONTACT_DOWNLOAD_EVENT);
    },
    onClickOpenQrCode() {
      this.modalIsVisible = true;
      this.onSendStat(events.QR_CODE_CLICK_EVENT);
    },
    onClickDownloadQr() {
      this.onSendStat(events.QR_CODE_DOWNLOAD_EVENT);
    },
    onSendStat(eventName) {
      this.statsApi.sendStat({
        userId: this.user.id,
        userAgent: navigator.userAgent,
        referrer: document.referrer,
        session: localStorage.getSession(),
        userType: userType.B2B,
      },
      eventName);
    },

    brandingLogo() {
      return this.user.headquarter.logo ? this.user.headquarter.logo : this.user.company.logo;
    },
  },
};
</script>

<style scoped lang="sass">
@import "../style/organisms/container.sass"
</style>
