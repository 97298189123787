export const VISIT_EVENT = 'visit';
export const PHONE_CLICK_EVENT = 'phone_click';
export const EMAIL_CLICK_EVENT = 'email_click';
export const LINKEDIN_CLICK_EVENT = 'linkedin_click';
export const WEB_CLICK_EVENT = 'web_click';
export const ADDRESS_CLICK_EVENT = 'address_click';
export const QR_CODE_CLICK_EVENT = 'qr_code_click';
export const CONTACT_DOWNLOAD_EVENT = 'contact_download';
export const SHARE_CLICK_EVENT = 'share_click';
export const QR_CODE_DOWNLOAD_EVENT = 'qr_code_download';
