<template>
  <div class="input-avatar">
    <input
      type="file"
      id="upload-avatar-image"
      hidden
      ref="upload-avatar-image"
      @change="onSetUpFileUploader"
      accept="image/jpeg, image/png"
    >

    <div class="croppie-container-wrapper" :hidden="!changeAvatar">
      <div id="avatar-croppie"></div>
      <div class="croppie-update-action">
        <a class="link link-regular" @click="onUploadFile" v-if="!uploadingPhoto">Save photo</a>
      </div>
    </div>
    <div class="input-avatar__avatar" :hidden="changeAvatar">
      <div class="input-avatar__avatar__image">
        <Avatar :avatar="url" />
      </div>
      <a class="ButtonLink input-avatar__avatar__link" @click="onClickChangePhoto">
        <img :src="require('@/assets/add.svg')" />
      </a>
    </div>
  </div>
</template>

<script>
import InputImage from '@/domain/models/InputImage';
import Avatar from '@/components/Avatar/Avatar.vue';
import Croppie from 'croppie';

const DefaultAvatar = require('@/components/Avatar/Avatar.svg');

export default {
  components: { Avatar },
  name: 'InputAvatar',
  props: {
    value: InputImage,
  },
  data: () => ({
    changeAvatar: false,
    uploadingPhoto: false,
    croppie: null,
    url: '',
  }),
  async created() {
    this.url = this.value.getUrl || DefaultAvatar;
  },
  async mounted() {
    this.setUpCroppie();

    this.$on('imageUploaded', (imageData) => {
      // ??
      this.img = imageData;
      this.croppie.destroy();
      this.setUpCroppie(imageData);
    });
  },
  methods: {
    onClickChangePhoto() {
      this.$refs['upload-avatar-image'].click();
    },
    setUpCroppie() {
      const el = document.getElementById('avatar-croppie');
      this.croppie = new Croppie(el, {
        viewport: { width: 126, height: 126, type: 'circle' },
        boundary: { width: 146, height: 146, type: 'circle' },
        showZoomer: true,
        enableOrientation: false,
        customClass: 'croppie',
      });
      this.croppie.bind({
        url: this.url,
      });
    },
    onUploadFile() {
      this.uploadingPhoto = true;
      this.croppie.result({
        type: 'canvas',
        size: 'original',
        circle: true,
      })
        .then((response) => {
          this.url = response;
          // eslint-disable-next-line prefer-const
          let [type, data] = response.split(',');
          type = type.replace(';base64', '');
          const [, extension] = type.split('/');

          const inputAvatarData = {
            url: response,
            data,
            extension,
          };

          this.$emit('change', new InputImage(inputAvatarData));
          this.uploadingPhoto = false;
          this.changeAvatar = false;
          this.$emit('shown');
        });
    },
    onSetUpFileUploader(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      if (!files.length) {
        return;
      }

      this.changeAvatar = true;
      this.createImage(files[0]);
      this.$emit('hidden');
    },
    createImage(file) {
      const reader = new FileReader();
      const vm = this;

      reader.onload = (e) => {
        vm.url = e.target.result;
        vm.$emit('imageUploaded', e.target.result);
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style lang="sass">
  @import "@/style/settings/colors"
  .croppie
    input[type=range]
      &::-webkit-slider-thumb
        -webkit-appearance: none
        background: $color__secondary--free
      &::-moz-range-thumb
        background: $color__secondary--free
      &::-ms-thumb
        background: $color__secondary--free
</style>

<style lang="sass" scoped>
  @import "@/style/settings/size"
  @import "@/style/settings/colors"
  .croppie
    .cr-boundary
      background-color: #ff0000 !important
      input[type=range]
        background-color: #ff0000 !important
        &::-webkit-slider-thumb
          -webkit-appearance: none
          background: #ffff00

  .input-avatar
    position: relative
    .croppie-container-wrapper
      height: 100vh
      .croppie-container
        height: unset
      .croppie-update-action
        text-align: center
        a
          cursor: pointer
          color: $color__secondary--free
    &__avatar
      display: inline-block
      margin-bottom: 2rem
      position: relative
      max-width: 126px
      &[hidden]
        display: none
      &__image
        position: relative
        img
          border-radius: 50%
          width: $avatar_size
          height: $avatar_size
        &::before
          content: " "
          position: absolute
          border-radius: 50%
          border: 4px solid #FFFFFF99
          width: $avatar_size - 16
          height: $avatar_size - 16
          left: 4px
          top: 4px

      &__link
        cursor: pointer
        position: absolute
        bottom: 13px
        right: 5px
</style>
