<template>
  <div
    class="container__content"
    :style="companyStyleColors"
    v-if="dataIsLoaded">
    <Modal
      :qrSrc="this.qrURL"
      :visible="modalIsVisible"
      @onClickDownloadQr="onClickDownloadQr()"
      @onClickExitModal="modalIsVisible = false"
    />
    <FreeHeader
      qr-visible
      @onClickQrCode="onClickOpenQrCode()"
      :user="user"
    />
    <Card>
      <template v-slot:avatar>
        <div class="card__img">
          <div class="UserCard__border"/>
          <Avatar
            :avatar="user.getAvatar.getUrl || require('@/components/Avatar/Avatar.svg')"
            :ts="user.getUpdatedAtTimestamp()"
          />
        </div>
      </template>
      <template v-slot:content>
        <Spacer />
        <div class="text__title">{{ user.getFirstName }} {{ user.getLastName }}</div>
        <Spacer size="spacer__XXS" />
        <div class="text__subtitle">{{ user.getRole }}</div>
        <Spacer size="spacer__XXS" />
        <a
          class="text__subtitle"
          :href="user.getCompany.getCompanyUrl"
          target="_blank"
        >
          {{ user.getCompany.getName }}
        </a>
        <Spacer size="spacer__XXXL" />
        <a
          :href="vCardURL"
          class="btn btn__primary"
        >
          Save contact
        </a>
      </template>
    </Card>
    <UserBio :bio="user.getBio" v-if="user.getBio"></UserBio>
    <ContactInfo :user="user" @onSendStat="onSendStat"></ContactInfo>
    <button class="btnFloat" @click="handleShare" v-if="existShareNativeOption()">
      <img :src="require('@/assets/arrow.svg')" />
    </button>
    <div class="UserCard__footer">
      <p class="UserCard__poweredTitle">Powered by</p>
      <a
        href="https://www.mycontact.app/"
        target="_blank"
      >
      <div class="logo"
        :style="'background-image:url(' + require('@/assets/mycontact.svg') + ')'" />
      </a>
    </div>
    <InstallAlert
      v-if="isMyUser && isMobileDevice && showInstallAlert"
      @handleClose="handleCloseInstallAlert"
    />
  </div>
</template>

<script>
import FreeHeader from '@/components/FreeHeader/FreeHeader.vue';
import Card from '@/components/Card/Card.vue';
import ContactInfo from '@/components/ContactInfo.vue';
import Modal from '@/components/Modal/Modal.vue';
import Spacer from '@/components/Common/Spacer/Spacer.vue';
import Avatar from '@/components/Avatar/Avatar.vue';
import UserBio from '@/components/UserBio.vue';
import InstallAlert from '@/components/InstallAlert/InstallAlert.vue';
import colorTransformer from '@/utils/colorTransformer';
import { isMobile } from '@/utils/deviceHelper';
import StatsAPI from '@/api/StatsAPI';
import * as localStorage from '@/utils/localStorage';
import * as events from '@/utils/events';
import * as userType from '@/utils/userType';

export default {
  name: 'FreeViewCard',
  components: {
    UserBio,
    Avatar,
    Modal,
    ContactInfo,
    FreeHeader,
    Card,
    Spacer,
    InstallAlert,
  },
  metaInfo: {
    meta: [
      { name: 'robots', content: 'noindex, nofollow' },
    ],
  },
  data: () => ({
    company: null,
    user: {},
    modalIsVisible: false,
    dataIsLoaded: false,
    vCardURL: null,
    companyStyleColors: {},
    showInstallAlert: localStorage.showInstallAlert() !== 'false',
  }),
  async created() {
    this.lockScreen();

    this.$store.dispatch('getUserById', this.$route.params.user_id)
      .then((res) => {
        this.user = res;
        this.vCardURL = `${process.env.VUE_APP_API_URL}/free/users/${this.user.getId}/vcf`;
        this.qrURL = `${process.env.VUE_APP_API_URL}/free/users/${this.user.getId}/qrcode`;
        const color = this.user.getCompany.getPrimaryColor || '#000000';
        this.companyStyleColors = colorTransformer(color);

        this.dataIsLoaded = true;
        this.unlockScreen();
        this.onSendStat(events.VISIT_EVENT);
      });
  },
  methods: {
    handleShare() {
      const role = this.user.getRole || '';
      const company = this.user.getCompany.getName ? `@ ${this.user.getCompany.getName}` : '';
      const query = this.$router.resolve({ name: 'free-view-card', params: { user_id: this.user.getId } }).href;
      const host = window.location.origin;
      const url = `${host}${query}`;
      const shareData = {
        title: `Digital Card from ${this.user.getFirstName}`,
        text: `${this.user.getName}'s Digital Card  - ${role} ${company}`,
        url,
      };

      navigator.share(shareData)
        .then(() => {
        })
        .catch(() => {
        });
    },
    existShareNativeOption() {
      return !!navigator.share;
    },
    handleCloseInstallAlert() {
      this.showInstallAlert = false;
      localStorage.hideInstallAlert();
    },

    onClickAddContacts() {
      this.onSendStat(events.CONTACT_DOWNLOAD_EVENT);
    },
    onClickOpenQrCode() {
      this.modalIsVisible = true;
      this.onSendStat(events.QR_CODE_CLICK_EVENT);
    },
    onClickDownloadQr() {
      this.onSendStat(events.QR_CODE_DOWNLOAD_EVENT);
    },
    onSendStat(eventName) {
      const statsApi = new StatsAPI();
      statsApi.sendStat({
        freeCardId: this.user.id,
        userAgent: navigator.userAgent,
        referrer: document.referrer,
        session: localStorage.getSession(),
        userType: userType.B2C,
      },
      eventName);
    },
  },
  computed: {
    isMobileDevice() {
      return isMobile();
    },
    isMyUser() {
      const userUrlId = this.$route.params.user_id;
      const userLoggedId = this.$store.state.user.getId;

      return userUrlId === userLoggedId;
    },
  },
};
</script>

<style scoped lang="sass">
@import "../style/organisms/container.sass"
@import "../style/pages/userCard.sass"
</style>
