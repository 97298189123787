import CompanyTransformer from '@/transformers/CompanyTransformer';
import HeadQuarterTransformer from '@/transformers/HeadQuarterTransformer';

class UserTransformer {
  static fetch(user) {
    return {
      id: user.id,
      username: user.userName,
      name: `${user.firstName} ${user.lastName}`,
      role: user.role,
      phone: user.phone,
      mail: user.email,
      linkedin: user.linkedIn,
      bio: user.bio,
      avatar: user.avatar,
      qr_code: user.qrCode,
      company: CompanyTransformer.fetch(user.company),
      head_quarter: HeadQuarterTransformer.fetch(user.headQuarter),
    };
  }
}

export default UserTransformer;
