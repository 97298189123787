<template>
  <div
    class="ActionCard"
    @click.prevent="handleUser"
  >
    <div
      class="ActionCard__actions"
      v-on:click.stop="handleActions"
    >
      <img
        src="@/assets/actions.svg"
      />
    </div>
    <div class="ActionCard__avatar">
      <Avatar
        :avatar="user.getAvatar.getUrl || require('@/components/Avatar/Avatar.svg')"
        :ts="user.getUpdatedAtTimestamp()"
        extraClass="small"
      />
    </div>
    <Spacer size="spacer__XXS" />
    <span class="ActionCard__name">{{ user.getFirstName }} {{ user.getLastName }}</span>
    <Spacer size="spacer__XXXS" />
    <span class="ActionCard__text">
      <span v-if="user.getRole">{{ user.getRole }}</span>
      <span v-if="user.getCompany.getName"> at {{ user.getCompany.getName }}</span>
    </span>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar/Avatar.vue';
import Spacer from '@/components/Common/Spacer/Spacer.vue';

export default {
  name: 'ActionCard',
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleUser() {
      this.$emit('handleUser');
    },

    handleActions() {
      this.$emit('handleActions');
    },
  },
  components: {
    Avatar,
    Spacer,
  },
};
</script>

<style scoped lang="sass">
@import "./ActionCard.sass"
</style>
