import InputImage from '../domain/models/InputImage';

class SaveableUserTransformer {
  static fetch(user) {
    let avatar = null;
    let logo = null;

    if (user.avatar.data !== '' && user.avatar.extension !== '') {
      avatar = new InputImage({
        data: user.avatar.data,
        extension: user.avatar.extension,
      });
    }
    if (user.logo.data !== '' && user.logo.extension !== '') {
      logo = new InputImage({
        data: user.logo.data,
        extension: user.logo.extension,
      });
    }
    return {
      avatar,
      firstName: user.first_name || null,
      lastName: user.last_name || null,
      company: user.company.name || null,
      role: user.role || null,
      bio: user.bio || null,
      web: user.company.company_url || null,
      linkedIn: user.linkedin || null,
      X: user.X || null,
      phone: user.phone || null,
      address: user.head_quarter.address || null,
      primaryColor: user.company.primary_color || null,
      logo,
    };
  }
}

export default SaveableUserTransformer;
