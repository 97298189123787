<template>
  <div class="input">
    <div class="input__label">
      {{ label }}
    </div>
    <div class="input__input">
      <textarea
        :rows="rows"
        :value="value"
        @input="$emit('input', $event.target.value)"
        :placeholder="placeholder"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Textarea',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: String,
    rows: {
      type: Number,
      default: 3,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="sass" scoped>
  @import "@/style/settings/colors"
  .input
    &__label
      color: $color__text--free
      margin-bottom: 5px
    &__input
      margin-bottom: 15px
      textarea
        padding: 10px 15px
        border-radius: 10px
        border: 1px solid #EEF2FE
        width: 100%
        caret-color: $color__primary--free
        outline: none
        appearance: none
        -moz-appearance: none
        -webkit-appearance: none
</style>
