<template>
  <BaseModal v-if="visible" class="modal">
    <div class="modal__close" @click="$emit('onClickExitModal')">
      <SVGViewer img="close" />
    </div>
    <div class="modal__body">
      <Card modal-class="card__info__qr">
        <template v-slot:content>
          <img :src="qrSrc" alt="qr-code"/>
        </template>
      </Card>
      <div class="download__container">
        <SVGViewer img="download"></SVGViewer>
        <a :href="qrSrc" class="text__title" @click="$emit('onClickDownloadQr')">
          Guardar en mis fotos
        </a>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import SVGViewer from '@/components/SVGViewer.vue';
import Card from '@/components/Card/Card.vue';
import BaseModal from '@/components/BaseModal/BaseModal.vue';

export default {
  name: 'Modal',
  components: { Card, SVGViewer, BaseModal },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    qrSrc: String,
    username: String,
    company: String,
  },
};
</script>

<style scoped lang="sass">
@import "./Modal.sass"
.download__container
  display: flex
  align-items: center
  a
    padding-left: 1rem
    text-decoration: none
    cursor: pointer
</style>
