import createUserSession from '@/utils/createUserSession';
import UsersAPI from '@/api/UsersAPI';
import UserFreeTransformer from '../transformers/UserFreeTransformer';

export default {
  createSession({ commit }) {
    commit('createSession', createUserSession());
  },

  authRequest(_, email) {
    return (new UsersAPI()).authRequest(email)
      .then((res) => res)
      .catch((res) => Promise.reject(res));
  },

  getUserByToken({ commit }, token) {
    return (new UsersAPI()).getUserFree(token)
      .then((res) => {
        commit('setUser', res);
        commit('setToken', token);
        return res;
      })
      .catch((res) => Promise.reject(res));
  },

  getUserById(_, id) {
    return (new UsersAPI()).getUserFreeById(id)
      .then((res) => res)
      .catch((res) => Promise.reject(res));
  },

  saveUserFree({ commit, state }, user) {
    return (new UsersAPI()).saveUserFree({ user, token: state.token })
      .then((res) => {
        const newUser = {
          id: state.user.getId,
          updatedAt: state.user.updatedAt,
          ...user,
          avatar: user.avatar ? user.avatar.getImageDataUrl() : state.user.avatar.getUrl,
          logo: user.logo ? user.logo.getImageDataUrl() : state.user.company.logo.getUrl,
          email: state.user.mail,
        };
        commit('setUser', UserFreeTransformer.fetch(newUser));
        return res;
      })
      .catch((res) => Promise.reject(res));
  },
};
