<template>
  <div>
    <div class="header">
      <img
        :src="user.getCompany.getLogo.getUrl || require('@/components/Logo/Logo.svg')"
        alt="company-logo"
        class="header__logo"
        :class="{ pointer: isLoggedUser }"
        @click="onGoTo('free-home-card')"
      />
      <div class="header__wrapper">
        <div class="edit" v-if="isLoggedUser">
          <router-link :to="{ name: 'free-edit-card' }">Edit</router-link>
        </div>
        <div class="qr" @click="$emit('onClickQrCode')" v-if="qrVisible">
          <SVGViewer img="qr-code" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SVGViewer from '@/components/SVGViewer.vue';
import User from '@/domain/models/User';

export default {
  name: 'FreeHeader',
  components: { SVGViewer },
  props: {
    user: {
      type: User,
      required: true,
    },
    qrVisible: Boolean,
  },
  computed: {
    isLoggedUser() {
      return this.user.getId === this.$store.getters.user.getId;
    },
  },
  methods: {
    onGoTo(route) {
      if (this.isLoggedUser) {
        this.$router.push({ name: route });
      }
    },
  },
};
</script>

<style scoped lang="sass">
@import "./FreeHeader.sass"
</style>
