<template>
  <div class="Avatar" :class="extraClass">
    <img
      :src="src"
      alt="user-avatar"
      class="Avatar__img"
    />
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    avatar: String,
    ts: String,
    extraClass: String,
  },
  computed: {
    src() {
      let src = this.avatar;
      if (!src.match(/^data:/) && this.ts) {
        src += `?ts=${this.ts}`;
      }
      if (this.avatar === '' || this.avatar === null) {
        src = 'https://via.placeholder.com/600';
      }
      return src;
    },
  },
};
</script>

<style lang="sass" scoped>
  .Avatar
    position: relative
    line-height: 0
    &.small
      &::before
        border-width: 2px
    &::before
      content: ""
      width: 100%
      height: 100%
      border: 4px solid hsla(0, 0%, 100%, .6)
      border-radius: 50%
      position: absolute
      top: 0
      left: 0
      box-sizing: border-box
    &__img
      max-width: 100%
      max-height: 100%
      image-rendering: auto
      image-rendering: crisp-edges
    </style>
