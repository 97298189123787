<template>
  <div class="Overlay">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Overlay',
};
</script>

<style lang="sass">
@import "./Overlay.sass"
</style>
