<template>
  <div class="input-logo">
    <input
      type="file"
      id="upload-logo-image"
      hidden
      ref="upload-logo-image"
      @change="onSetUpFileUploader"
      accept="image/jpeg, image/png"
    >

    <div class="input-logo__label">
      {{ label }}
    </div>
    <div class="croppie-container-wrapper" :hidden="!changeLogo">
      <div id="logo-croppie"></div>
      <div class="croppie-update-action">
        <a class="link link-regular" @click="onUploadFile" v-if="!uploadingPhoto">Save logo</a>
      </div>
    </div>
    <div class="input-logo__logo" :hidden="changeLogo">
      <div class="input-logo__logo__image">
        <Logo :logo="url" />
      </div>
      <a class="link link-regular input-logo__logo__link" @click="onClickChangePhoto">
        Change logo
      </a>
    </div>
  </div>
</template>

<script>
import InputImage from '@/domain/models/InputImage';
import Logo from '@/components/Logo/Logo.vue';
import Croppie from 'croppie';

const DefaultLogo = require('@/components/Logo/Logo.svg');

export default {
  components: { Logo },
  name: 'InputLogo',
  props: {
    label: String,
    value: InputImage,
  },
  data: () => ({
    changeLogo: false,
    uploadingPhoto: false,
    croppie: null,
    url: '',
  }),
  async created() {
    this.url = this.value.getUrl || DefaultLogo;
  },
  async mounted() {
    this.setUpCroppie();

    this.$on('imageUploaded', (imageData) => {
      // ??
      this.img = imageData;
      this.croppie.destroy();
      this.setUpCroppie(imageData);
    });
  },
  methods: {
    onClickChangePhoto() {
      this.$refs['upload-logo-image'].click();
    },
    setUpCroppie() {
      const el = document.getElementById('logo-croppie');
      this.croppie = new Croppie(el, {
        viewport: { width: 200, height: 126, type: 'square' },
        boundary: { width: 220, height: 146, type: 'square' },
        showZoomer: true,
        enableOrientation: false,
        enforceBoundary: false,
        customClass: 'croppie',
      });
      this.croppie.bind({
        url: this.url,
      });
    },
    onUploadFile() {
      this.uploadingPhoto = true;
      this.croppie.result({
        type: 'canvas',
        size: 'original',
        circle: false,
      })
        .then((response) => {
          this.url = response;
          // eslint-disable-next-line prefer-const
          let [type, data] = response.split(',');
          type = type.replace(';base64', '');
          const [, extension] = type.split('/');

          const inputLogoData = {
            url: response,
            data,
            extension,
          };

          this.$emit('change', new InputImage(inputLogoData));
          this.uploadingPhoto = false;
          this.changeLogo = false;
          this.$emit('shown');
        });
    },
    onSetUpFileUploader(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      if (!files.length) {
        return;
      }

      this.changeLogo = true;
      this.createImage(files[0]);
      this.$emit('hidden');
    },
    createImage(file) {
      const reader = new FileReader();
      const vm = this;

      reader.onload = (e) => {
        vm.url = e.target.result;
        vm.$emit('imageUploaded', e.target.result);
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style lang="sass">
  @import "@/style/settings/colors"
  .croppie
    input[type=range]
      &::-webkit-slider-thumb
        -webkit-appearance: none
        background: $color__secondary--free
      &::-moz-range-thumb
        background: $color__secondary--free
      &::-ms-thumb
        background: $color__secondary--free
</style>

<style lang="sass" scoped>
  @import "@/style/settings/size"
  @import "@/style/settings/colors"
  .input-logo
    position: relative
    &__label
      color: $color__text--free
      margin-bottom: 5px
    .croppie-container-wrapper
      margin-bottom: 30px
      .croppie-container
      .croppie-update-action
        text-align: center
        a
          cursor: pointer
          color: $color__secondary--free
    &__logo
      text-align: center
      display: inline-block
      margin-bottom: 2rem
      position: relative
      width: 100%
      &[hidden]
        display: none
      &__image
        position: relative
        img
          width: $avatar_size

      &__link
        cursor: pointer
        color: $color__secondary--free
</style>
