<template>
  <router-link
    class="member"
    :to="{ name: 'user-card', params: {
      company: user.company.company,
      username: user.username
    }}"
  >
    <div class="member__content">
      <Avatar :src="user.avatar"></Avatar>
      <span class="member__name">{{ user.name }}</span>
    </div>
  </router-link>
</template>

<script>

import Avatar from '@/components/Avatar/Avatar.vue';

export default {
  name: 'Member',
  components: { Avatar },
  props: {
    user: Object,
  },
};
</script>

<style scoped lang="sass">
@import "./Member.sass"
</style>
