<template>
  <div class="contactInfo">
    <InfoRow
      img="phone"
      :href="'tel:' + user.phone"
      :text="user.phone"
      v-if="user.phone"
      @onClickInfoRow="onClickPhoneInfoRow()"
    />
    <InfoRow
      img="email"
      :href="'mailto:' + user.mail"
      :text="user.mail"
      v-if="user.mail"
      @onClickInfoRow="onClickEmailInfoRow()"
    />
    <InfoRow
      img="linkedin"
      :href="user.linkedin"
      text="Ver mi perfil"
      target="_blank"
      rel="noreferrer"
      v-if="user.linkedin"
      @onClickInfoRow="onClickLinkedInInfoRow()"
    />
    <InfoRow
      img="X"
      :href="user.X"
      :text="getXProfileName()"
      target="_blank"
      rel="noreferrer"
      v-if="user.X"
      @onClickInfoRow="onClickLinkedInInfoRow()"
    />
    <InfoRow
      img="link"
      :href="user.company.getCompanyUrl"
      :text="getCompanyURLText"
      target="_blank"
      rel="noreferrer"
      v-if="user.company.company_url"
      @onClickInfoRow="onClickWebInfoRow()"
    />
    <InfoRow
      class="contactInfo__address"
      img="location"
      :href="user.head_quarter.maps_url"
      :text="user.head_quarter.address"
      target="_blank"
      rel="noreferrer"
      v-if="user.head_quarter.address"
      @onClickInfoRow="onClickAddressInfoRow()"
    />
  </div>
</template>

<script>
import InfoRow from '@/components/InfoRow.vue';
import * as events from '@/utils/events';

export default {
  name: 'ContactInfo',
  components: { InfoRow },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getCompanyURLText() {
      return this.user.company.company_url.replace(/(^\w+:|^)\/\//, '').replace(/[/]$/, '');
    },
  },
  methods: {
    onClickPhoneInfoRow() {
      this.sendStat(events.PHONE_CLICK_EVENT);
    },
    onClickEmailInfoRow() {
      this.sendStat(events.EMAIL_CLICK_EVENT);
    },
    onClickLinkedInInfoRow() {
      this.sendStat(events.LINKEDIN_CLICK_EVENT);
    },
    onClickWebInfoRow() {
      this.sendStat(events.WEB_CLICK_EVENT);
    },
    onClickAddressInfoRow() {
      this.sendStat(events.ADDRESS_CLICK_EVENT);
    },
    sendStat(eventName) {
      this.$emit('onSendStat', eventName);
    },
    getXProfileName() {
      if (!this.user.X) {
        return '';
      }
      return `@${this.user.X.split('/').pop()}`;
    },
  },
};
</script>

<style scoped lang="sass">
@import './ContactInfo.sass'
</style>
