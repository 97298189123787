<template>
  <div class="card">
    <slot name="avatar"></slot>
    <div class="card__info" :class="modalClass">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    modalClass: {
      type: String,
      required: false,
    },
  },
};
</script>
