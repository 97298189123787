export function isIos() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

export function isAndroid() {
  return /android/i.test(navigator.userAgent);
}

export function isMobile() {
  return isIos() || isAndroid();
}
