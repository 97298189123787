<template>
  <div class="container__content">
    <p>Page not found</p>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>

<style scoped>

</style>
