<template>
  <a class="InfoRow" :href="href" :target="target" :rel="rel" @click="$emit('onClickInfoRow')">
    <div class="InfoRow__imageContainer">
      <SVGViewer :img="img" />
    </div>
    {{ text }}
  </a>
</template>

<script>
import SVGViewer from '@/components/SVGViewer.vue';

export default {
  name: 'InfoRow',
  components: { SVGViewer },
  props: {
    img: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: false,
    },
    target: {
      type: String,
      default: '_self',
    },
    rel: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="sass">
@import './InfoRow.sass'
</style>
