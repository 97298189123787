<template>
  <inline-svg :src="require('@/assets/' + img + '.svg')" :title="img" />
</template>

<script>
export default {
  name: 'SVGViewer',
  props: {
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="sass">
@import "./SVGViewer"
</style>
