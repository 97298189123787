<template>
  <p class="userBio text__title">{{ bio }}</p>
</template>

<script>
export default {
  name: 'UserBio',
  props: {
    bio: String,
  },
};
</script>

<style scoped lang="sass">
@import "./UserBio.sass"
</style>
