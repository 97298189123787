<template>
  <div class="modal">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseModal',
};
</script>

<style lang="sass" scoped>
@import "../../style/settings/colors"
@import "../../style/settings/responsive"

.modal
  height: 100vh
  z-index: 3
  background-color: $white
  position: fixed
  width: 100%
  border-radius: 10px
  @media screen and (min-width: $break__computer)
    max-width: $desktop__width
</style>
