<template>
  <div class="filterInput">
    <input
      v-model=textToFilter
      placeholder="Buscar"
      type="text"
      @input="$emit('changeFilter', textToFilter)"
    >
    <SVGViewer img="lens" />
  </div>
</template>

<script>
import SVGViewer from '@/components/SVGViewer.vue';

export default {
  name: 'FilterInput',
  components: { SVGViewer },
  data: () => ({
    textToFilter: '',
  }),
};
</script>

<style scoped lang="sass">
  @import './FilterInput.sass'
</style>
